import React, { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/page-header";

const conTitle = "Nous sommes à votre service";

const ContactPage = () => {
  const [formStatus, setFormStatus] = React.useState("Envoyer un message");
  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submited");
    const { name, email, phone, message } = e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
    console.log(conFom);
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Nous contacter"} curPage={"Contact"} />

      <div className="contact-section">
        <div
          className="contact-top padding-top padding-bottom bg-attachment"
          style={{ backgroundImage: "url(/assets/images/video/bg.jpg)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <div className="contact-form-wrapper text-center">
                  <div className="section-header">
                    <h2>{conTitle}</h2>
                  </div>
                  <form className="contact-form" onSubmit={onSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Votre Nom"
                        id="name"
                        name="name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Votre Email"
                        id="email"
                        name="email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Sujet"
                        id="subject"
                        name="subject"
                        required
                      />
                    </div>
                    <div className="form-group w-100">
                      <textarea
                        name="message"
                        rows="8"
                        id="message"
                        placeholder="Votre Message"
                        required
                      ></textarea>
                    </div>
                    <div className="form-group w-100 text-center">
                      <button className="default-button" type="submit">
                        <span>{formStatus}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
