const title = "Un esprit, club.";
const desc =
  "Votre oasis en ligne pour des parties endiablées entre amis. Rejoignez-nous pour vivre des moments de convivialité et de compétition dans une atmosphère chaleureuse. Connectez-vous et jouez où que vous soyez, partagez des rires et des stratégies, le tout dans un cadre accueillant. Plongez dans l'univers du Belote Club et faites de chaque partie une aventure mémorable";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon-1.png",
    imgAlt: "Icon",
    title: "1..2...3 Progressez",
    desc: "Apprennez les uns avec les autres, et améliorez vous avec notre communauté, peut-etre que vous allez y découvrir de nouvelles stratégies de jeu !",
  },
  {
    imgUrl: "assets/images/about/icon-2.png",
    imgAlt: "Icon",
    title: "Allo?",
    desc: "Jouez en ligne avec les autres joueurs et interagissez les uns avec les autres, que ce soit par le biais des chats, des messages privés ou même de canaux vocaux",
  },
];

const AboutTwo = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="section-wrapper padding-top">
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="about-image position-relative">
                <img
                  src="assets/images/game/belote.jpg"
                  alt="about-image"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="about-wrapper">
                <div className="section-header">
                  <h2>{title}</h2>
                </div>
                <div className="about-content">
                  <p>{desc}</p>
                  <ul className="about-list">
                    {aboutList.map((val, i) => (
                      <li className="about-item d-flex flex-wrap" key={i}>
                        <div className="about-item-thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className="about-item-content">
                          <h5>{val.title}</h5>
                          <p>{val.desc}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
