import { useState } from "react";
import { NavLink, Link } from "react-router-dom";

const phoneNumber = "+800-123-4567 6587";
const address = "Beverley, New York 224 USA";

const socialList = [
  {
    iconName: "icofont-facebook-messenger",
    siteLink: "#",
  },
  {
    iconName: "icofont-twitter",
    siteLink: "#",
  },
  {
    iconName: "icofont-vimeo",
    siteLink: "#",
  },
  {
    iconName: "icofont-skype",
    siteLink: "#",
  },
  {
    iconName: "icofont-rss-feed",
    siteLink: "#",
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className="container">
        <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
          <div className="header-menu-part">
            <div className="header-bottom">
              <div className="header-wrapper justify-content-lg-end">
                <div className="mobile-logo d-lg-none">
                  <Link to="/">
                    <img
                      src="assets/images/logo/logo.png"
                      alt="logo"
                      style={{ height: "20px" }}
                    />
                  </Link>
                </div>
                <div className="menu-area">
                  <ul className={`menu ${menuToggle ? "active" : ""}`}>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/blog">Blog</NavLink>
                    </li> */}
                    <li>
                      <NavLink to="/about">A propos de nous</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/contact">Contact</NavLink>
                    </li> */}
                  </ul>
                  <Link to="/login" className="login">
                    <i className="icofont-user"></i> <span>Connexion</span>{" "}
                  </Link>
                  <Link to="/signup" className="signup">
                    <i className="icofont-users"></i> <span>Inscription</span>
                  </Link>

                  <div
                    className={`header-bar d-lg-none ${
                      menuToggle ? "active" : ""
                    }`}
                    onClick={() => setMenuToggle(!menuToggle)}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div
                    className="ellepsis-bar d-lg-none"
                    onClick={() => setSocialToggle(!socialToggle)}
                  >
                    <i className="icofont-info-square"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
