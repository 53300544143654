import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/page-header";

const title = "Ouvrir une session";

const socialList = [
  {
    imgUrl: "assets/images/match/social-1.png",
    imgAlt: "social",
    siteLink: "#",
  },
  {
    imgUrl: "assets/images/match/social-2.png",
    imgAlt: "social",
    siteLink: "#",
  },
  {
    imgUrl: "assets/images/match/social-3.png",
    imgAlt: "social",
    siteLink: "#",
  },
];

const LogIn = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Bon retour !"} curPage={"Login"} />
      <div className="login-section padding-top padding-bottom">
        <div className=" container">
          <div className="account-wrapper">
            {/* <h3 className="title">{title}</h3>
         <form className="account-form text-start">
              <div className="form-group">
                <label>Identifiant:</label>
                <input type="text" name="username" required />
              </div>
              <div className="form-group">
                <label>Mot de passe:</label>
                <input type="password" name="password" required />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Se souvenir de moi ?</label>
                  </div>
                  <Link to="/forpass">Mot de passe oublié ?</Link>
                </div>
              </div>
              <div className="form-group">
                <button className="d-block default-button">
                  <span>Se connecter</span>
                </button>
              </div>
            </form> */}{" "}
            <h4 className="title">Bientôt disponible</h4>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Je n'ai pas de compte ? <Link to="/signup"> S'inscrire</Link>
              </span>
              {/* <span className="or">
                <span>ou</span>
              </span>
              <h5 className="subtitle">Autres moyens de connexion</h5>
              <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center mt-4">
                {socialList.map((val, i) => (
                  <li key={i}>
                    <a href={val.siteLink}>
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </a>
                  </li>
                ))} 
              </ul>*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default LogIn;
