import { Component } from "react";
import { Link } from "react-router-dom";

const title = "Bienvenue au club";

const collectionList = [
  {
    imgUrl: "assets/images/game/01.png",
    imgAlt: "game-img",
    title: "Gratuit",
    desc: "Jouez à la belote en ligne gratuitement, sans frais cachés ni abonnements. Notre plateforme offre une expérience de jeu accessible à tous !",
  },
  {
    imgUrl: "assets/images/game/02.png",
    imgAlt: "game-img",
    title: "club'Expérience",
    desc: "Plongez dans une expérience de belote en ligne exceptionnelle avec des fonctionnalités innovantes, un design intuitif et des tournois captivants !",
  },
  {
    imgUrl: "assets/images/game/03.png",
    imgAlt: "game-img",
    title: "Vous avez dit IA ?",
    desc: "Défiez-vous contre l'IA. Vivez des parties stimulantes, seul ou avec vos amis, chaque partie sera pour vous une nouvelle opportunité d'améliorer votre jeu !",
  },
];

const Collection = () => {
  return (
    <section className="collection-section padding-top padding-bottom">
      <div className="container">
        <div className="section-header">
          <h2>{title}</h2>
        </div>
        <div className="section-wrapper game">
          <div className="row g-4 justify-content-center">
            {collectionList.map((val, i) => (
              <div className="col-lg-4 col-sm-6 col-12" key={i}>
                <div className="game__item item-layer">
                  <div className="game__inner text-center">
                    <div className="game__thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="game__content">
                      <h4>{val.title}</h4>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;
