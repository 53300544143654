import { Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/page-header";

const collectionDataList = [
  {
    image: "assets/images/game/cheesgame.jpeg",
    imgAlt: "game-img",
    title: "Les Echecs",
    desc: "Jeu solo gratuit",
    btnText: "Jouer",
    catagory: "cat-1",
    id: 1,
  },
  {
    image: "assets/images/game/tetris.jpeg",
    imgAlt: "game-img",
    title: "Tétris",
    desc: "Jeu solo gratuit",
    btnText: "Jouer",
    catagory: "cat-2",
    id: 2,
  },
  {
    image: "assets/images/game/packman.webp",
    imgAlt: "game-img",
    title: "Packman",
    desc: "Jeu solo gratuit",
    btnText: "Jouer",
    catagory: "cat-3",
    id: 3,
  },
  {
    image: "assets/images/game/spaceinvader.webp",
    imgAlt: "game-img",
    title: "Space Invaders",
    desc: "Jeu solo gratuit",
    btnText: "Jouer",
    catagory: "cat-4",
    id: 4,
  },
  {
    id: 5,
    title: "Sudoku",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/Sudoku.png",
    link: "#",
    catagory: "match-three",
  },
  {
    id: 6,
    title: "Billard",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/Billard.jpg",
    link: "#",
    catagory: "match-one",
  },
  {
    id: 7,
    title: "Puissance 4",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/puissance4.jpg",
    link: "#",
    catagory: "match-three",
  },

  {
    id: 9,
    title: "Brick Breaker",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/BrickBreaker.jpg",
    link: "#",
    catagory: "match-one",
  },

  {
    id: 10,
    title: "Le solitaire",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/Le solitaire.png",
    link: "#",
    catagory: "match-three",
  },
  {
    id: 8,
    title: "Othello",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/Othello.jpg",
    link: "#",
    catagory: "match-two",
  },
  {
    id: 11,
    title: "Démineur",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/demineur.png",
    link: "#",
    catagory: "match-two",
  },
  {
    id: 12,
    title: "Jeu des paires",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/Jeu des paires.jpg",
    link: "#",
    catagory: "match-two",
  },
  {
    id: 13,
    title: "Morpion",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/Morpion.jpg",
    link: "#",
    catagory: "match-two",
  },
  {
    id: 14,
    title: "Le pendu",
    cate: "Jeu solo gratuit",
    image: "assets/images/game/le pendu.jpg",
    link: "#",
    catagory: "match-two",
  },
];

const GameListTwo = () => {
  const [items, setItems] = useState(collectionDataList);
  const filterItem = (categItem) => {
    const updateItems = collectionDataList.filter((curElem) => {
      return curElem.catagory === categItem;
    });
    setItems(updateItems);
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Nos autres jeux solo"} curPage={"games"} />
      <section className="match-section padding-top padding-bottom">
        <div className="container">
          <div className="section-wrapper">
            <div className="row g-4 match-grid grid">
              {items.map((elem) => {
                const { id, title, cate, image, link } = elem;
                return (
                  <div
                    className="col-lg-6 col-12 matchlistitem match-one"
                    key={id}
                  >
                    <div className="game__item item-layer">
                      <div className="game__inner text-center p-0">
                        <div className="game__thumb mb-0">
                          <img
                            src={image}
                            alt={title}
                            className="rounded-3 w-100"
                          />
                        </div>
                        <div className="game__overlay">
                          <div className="game__overlay-left">
                            <h4>{title}</h4>
                            <p>{cate}</p>
                          </div>
                          <div className="game__overlay-right">
                            <a href={link} className="default-button">
                              <span>
                                Jouer <i className="icofont-circled-right"></i>
                              </span>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default GameListTwo;
