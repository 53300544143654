import { Component } from "react";
import { Link } from "react-router-dom";

const titleh3 = "Bienvenue au";
const titleh2 = "Jouez à la belote en ligne gratuitement !";
const titleh1 = "Beloteclub";
const desc =
  "Vivez la belote en ligne autrement avec nos évènements et tournois exclusif !";
const btnText = "Ouvrir une table";

const Banner = () => {
  return (
    <section
      className="banner"
      style={{ backgroundImage: "url(/assets/images/banner/bg.jpg)" }}
    >
      <div className="container">
        <div className="row g-0">
          <div className="col-xl-6 col-lg-7 col-12">
            <div className="banner__content">
              <h3>{titleh3}</h3>
              <h1>{titleh1}</h1>
              <h2>{titleh2}</h2>
              <p>{desc}</p>
              <Link to="/login" className="default-button">
                <span>
                  {btnText} <i className="icofont-play-alt-1"></i>
                </span>{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
