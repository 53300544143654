import { Link } from "react-router-dom";
import RatingTwo from "../sidebar/rating-2";
import axios from "axios";
import React, { useState } from "react";

const desc =
  "Sacem 20010000026697 FLUX Vidéo No :10-10000004688 United States Copyright Code, Title 17; §106(4,5) and §114(b) respectively, and for use in ASCAP, BMI and SESAC.";
const blogTitle = "Derniers articles";
const newsTitle = "Suivez l'actualité !";
const newsDesc = "Restez branché en vous inscrivant à notre newsletter !";

const infoList = [
  {
    imgUrl: "assets/images/footer/icons/02.png",
    imgAlt: "icon",
    text: "Email : contact@beloteclub.fr",
  },
];

const socialList = [
  {
    imgUrl: "assets/images/match/social-1.png",
    imgAlt: "social",
    siteLink: "#",
  },
  {
    imgUrl: "assets/images/match/social-2.png",
    imgAlt: "social",
    siteLink: "#",
  },
  {
    imgUrl: "assets/images/match/social-3.png",
    imgAlt: "social",
    siteLink: "#",
  },
];

const blogList = [
  {
    imgUrl: "assets/images/footer/01.jpg",
    imgAlt: "footer-widget-img",
    title: "free Poker Game",
    name: "Poker",
    price: "$230",
  },
  {
    imgUrl: "assets/images/footer/02.jpg",
    imgAlt: "footer-widget-img",
    title: "CLUB Poker Game",
    name: "Poker",
    price: "$230",
  },
  {
    imgUrl: "assets/images/footer/03.jpg",
    imgAlt: "footer-widget-img",
    title: "Royal Poker Game",
    name: "Poker",
    price: "$230",
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function registerToNL(event) {
    event.preventDefault();
    alert("Nous n'acceptons pas d'inscription à la newsletter pour le moment.");
  }

  return (
    <footer
      className="footer-section"
      style={{ backgroundImage: "url(/assets/images/footer/bg.jpg)" }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row g-3 justify-content-center g-lg-0">
            {infoList.map((val, i) => (
              <div className="col-lg-4 col-sm-6 col-12" key={i}>
                <div className="footer-top-item lab-item">
                  <div className="lab-inner">
                    <div className="lab-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="lab-content">
                      <span>{val.text}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-middle padding-top padding-bottom">
        <div className="container">
          <div className="row padding-lg-top">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-lg-0">
                  <div className="fm-item-title mb-4">
                    <img src="assets/images/logo/logo.png" alt="logo" />
                  </div>
                  <div className="fm-item-content">
                    <p className="mb-4">{desc}</p>
                    {/* <ul className="match-social-list d-flex flex-wrap align-items-center">
                      {socialList.map((val, i) => (
                        <li key={i}>
                          <a href={val.siteLink}>
                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          </a>
                        </li>
                      ))}
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12"></div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item-3 mb-lg-0">
                  <div className="fm-item-title">
                    <h4>{newsTitle}</h4>
                  </div>
                  <div className="fm-item-content">
                    <p>{newsDesc}</p>
                    <form onSubmit={registerToNL}>
                      <div className="form-group mb-2">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Votre email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <button className="default-button">
                        <span>
                          S'inscrire <i className="icofont-circled-right"></i>
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-bottom-content text-center">
                <p>
                  &copy;{new Date().getFullYear()} Beloteclub - Tous droits
                  réservés
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
