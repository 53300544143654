import Pagination from "../sidebar/pagination"


const TournamentList = [
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
]


const TournamentListTwo = [
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
]


const TournamentListThree = [
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
]


const TournamentListFour = [
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '$10k Midweek Moolha',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: '100 4hr reloder',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'Master Of The Table',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
    {
        td1: 'The Wild Card',
        td2: '11:40',
        td3: '12:00',
        td4: '232',
        td5: '$1000',
        td6: '$5',
        td7: 'Yes',
    },
]


const Tournament = () => {
    return (
        <div className="tunament padding-top padding-bottom">
            <div className="container">
                <div className="section-wrapper">
                    <div className="achievement-area">
                        <ul className="nav nav-tabs align-items-center" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation" title="ALL">
                                <button className="nav-link active" id="tabAll-tab" data-bs-toggle="tab" data-bs-target="#tabAll" type="button" role="tab" aria-controls="tabAll" aria-selected="true">All</button>
                            </li>
                            <li className="nav-item" role="presentation" title="Blackjack">
                                <button className="nav-link" id="tabOne-tab" data-bs-toggle="tab" data-bs-target="#tabOne" type="button" role="tab" aria-controls="tabOne" aria-selected="false">Blackjack</button>
                            </li>
                            <li className="nav-item" role="presentation" title="Slots">
                                <button className="nav-link" id="tabTwo-tab" data-bs-toggle="tab" data-bs-target="#tabTwo" type="button" role="tab" aria-controls="tabTwo" aria-selected="false">Slots</button>
                            </li>
                            <li className="nav-item" role="presentation" title="Texas hold'em">
                                <button className="nav-link" id="tabThree-tab" data-bs-toggle="tab" data-bs-target="#tabThree" type="button" role="tab" aria-controls="tabThree" aria-selected="false">Texas hold'em</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="tabAll" role="tabpanel" aria-labelledby="tabAll-tab">
                                <table className="table text-white">
                                    <thead>
                                        <tr>
                                            <th>Tournament</th>
                                            <th>Starts In</th>
                                            <th>Ends In</th>
                                            <th>Players</th>
                                            <th>Prize($)</th>
                                            <th>Entry Fee</th>
                                            <th>Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TournamentList.map((val, i) => (
                                            <tr key={i}>
                                                <td>{val.td1}</td>
                                                <td>{val.td2}</td>
                                                <td>{val.td3}</td>
                                                <td>{val.td4}</td>
                                                <td>{val.td5}</td>
                                                <td>{val.td6}</td>
                                                <td>{val.td7}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex flex-wrap justify-content-between align-items-center tunament__footer">
                                    <div className="tunament__left">
                                        <h5>102 Tournament results</h5>
                                    </div>
                                    <div className="tunament__right">
                                        <Pagination />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tabOne" role="tabpanel" aria-labelledby="tabOne-tab">
                                <table className="table text-white">
                                    <thead>
                                        <tr>
                                            <th>Tournament</th>
                                            <th>Starts In</th>
                                            <th>Ends In</th>
                                            <th>Players</th>
                                            <th>Prize($)</th>
                                            <th>Entry Fee</th>
                                            <th>Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TournamentListTwo.map((val, i) => (
                                            <tr key={i}>
                                                <td>{val.td1}</td>
                                                <td>{val.td2}</td>
                                                <td>{val.td3}</td>
                                                <td>{val.td4}</td>
                                                <td>{val.td5}</td>
                                                <td>{val.td6}</td>
                                                <td>{val.td7}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <div className="d-flex flex-wrap justify-content-between align-items-center tunament__footer">
                                    <div className="tunament__left">
                                        <h5>102 Tournament results</h5>
                                    </div>
                                    <div className="tunament__right">
                                        <Pagination />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tabTwo" role="tabpanel" aria-labelledby="tabTwo-tab">
                                <table className="table text-white table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Tournament</th>
                                            <th>Starts In</th>
                                            <th>Ends In</th>
                                            <th>Players</th>
                                            <th>Prize($)</th>
                                            <th>Entry Fee</th>
                                            <th>Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TournamentListThree.map((val, i) => (
                                            <tr key={i}>
                                                <td>{val.td1}</td>
                                                <td>{val.td2}</td>
                                                <td>{val.td3}</td>
                                                <td>{val.td4}</td>
                                                <td>{val.td5}</td>
                                                <td>{val.td6}</td>
                                                <td>{val.td7}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                
                                <div className="d-flex flex-wrap justify-content-between align-items-center tunament__footer">
                                    <div className="tunament__left">
                                        <h5>102 Tournament results</h5>
                                    </div>
                                    <div className="tunament__right">
                                        <Pagination />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tabThree" role="tabpanel" aria-labelledby="tabThree-tab">
                                <table className="table text-white table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Tournament</th>
                                            <th>Starts In</th>
                                            <th>Ends In</th>
                                            <th>Players</th>
                                            <th>Prize($)</th>
                                            <th>Entry Fee</th>
                                            <th>Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TournamentListFour.map((val, i) => (
                                            <tr key={i}>
                                                <td>{val.td1}</td>
                                                <td>{val.td2}</td>
                                                <td>{val.td3}</td>
                                                <td>{val.td4}</td>
                                                <td>{val.td5}</td>
                                                <td>{val.td6}</td>
                                                <td>{val.td7}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                
                                <div className="d-flex flex-wrap justify-content-between align-items-center tunament__footer">
                                    <div className="tunament__left">
                                        <h5>102 Tournament results</h5>
                                    </div>
                                    <div className="tunament__right">
                                        <Pagination />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tournament;